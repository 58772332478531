<template>
  <nav class="sticky-top" style="align-self: flex-start; top: 1.5rem; z-index: 1;">
    <ul class="list-unstyled">
      <li v-for="item in items" class="item">
        <router-link :to="{ name: item.name }">
          <component :is="item.componentName" class="icon-large" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { AdjustmentsHorizontalIcon, FunnelIcon, PuzzlePieceIcon, RectangleGroupIcon, RectangleStackIcon, ShieldCheckIcon, UsersIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    AdjustmentsHorizontalIcon,
    FunnelIcon,
    PuzzlePieceIcon,
    RectangleGroupIcon,
    RectangleStackIcon,
    ShieldCheckIcon,
    UsersIcon,
  },

  computed: {
    items() {
      return [
        { name: 'publications-id-articles', componentName: 'RectangleStackIcon' },
        { name: 'publications-id-sections', componentName: 'RectangleGroupIcon' },
        { name: 'publications-id-actions', componentName: 'FunnelIcon' },
        { name: 'publications-id-extensions', componentName: 'PuzzlePieceIcon' },
        { name: 'publications-id-users', componentName: 'UsersIcon' },
        { name: 'publications-id-roles', componentName: 'ShieldCheckIcon' },
        { name: 'publications-id-settings', componentName: 'AdjustmentsHorizontalIcon' },
      ]
    },
  },
}
</script>

<style scoped>
nav {
  width: 62px;
  min-width: 62px;
  max-width: 62px;
}

nav .item {
  margin-bottom: 26px;
}

nav .item svg {
  margin: 0 auto;
  display: block;
}

nav a {
  transition: color 150ms ease-in-out;
  color: #bec2dd;
}

nav a.router-link-active {
  color: #0006B4;
}
</style>
