<template>
  <div class="container-fluid">
    <div class="d-flex">
      <ThePublicationNavigation />
      <div class="container" style="max-width: 1200px;">
        <div
          v-if="$store.state.publication.data.deactivatedAt"
          class="alert alert-warning mb-5"
          role="alert"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              This publication is scheduled for deactivation.
            </div>
            <div v-if="$store.getters['publication/isAdmin']">
              <a class="alert-link" @click="onReactivate">Reactivate</a>
            </div>
          </div>
        </div>
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import ThePublicationNavigation from '@/components/ThePublication/ThePublicationNavigation'

export default {
  components: {
    ThePublicationNavigation,
  },

  async mounted() {
    try {
      var { data } = await this.$http.get(`/publications/${ this.$route.params.publication }`)
    } catch (err) {
      this.$message.error(err.response.data.message)
    }
  },

  methods: {
    async onReactivate() {
      try {
        var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/deactivate`, {
          active: true,
        })

        if (!data.deactivatedAt) {
          this.$store.commit('publication/data', data)
          this.$message.success('Publication has been reactivated.')
        } else {
          this.$message.error('Publication could not be reactivated.')
        }
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      }
    },
  },
}
</script>
